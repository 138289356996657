
import { mapActions, mapGetters, mapMutations } from "vuex";
import offerFilters from "@/apollo/queries/offer/offerFilters";
import onlyFiltersRequest from "@/apollo/queries/offer/onlyFiltersPrice";
import offerUrl from "@/apollo/queries/offer/offerUrl";
import _ from "lodash";
import utm from "@/mixins/utm";
import metrika from "@/mixins/metrika";
import { timezoneOffsetCurrent } from "@/app/variables.js";
export default {
  mixins: [utm, metrika],
  data() {
    return {
      // urlVkPixel: 'https://top-fwz1.mail.ru/tracker?id=3329909;e=RG%3A/trg-pixel-16077757-1682325999253;_={{random}}'
    };
  },
  watch: {
    $route() {
      // if (this.urlValidate && this.reload) {
      if (this.urlValidate) {
        this.checkUrl();
      }
      if (this.$route.path === "/") {
        this.onlyFilterRequest({
          url: "/cars"
        });
      }
      this.clearData();

      !this.isFilterClick && document.body.scrollTo(0, -100);
    }
  },
  async fetch() {
    if (this.urlValidate) {
      await this.checkUrl();
    }

    if (this.$route.path === "/") {
      await this.onlyFilterRequest({
        url: "/cars"
      });
    }
  },
  computed: {
    ...mapGetters({
      chosen: "filters/filters/chosen",
      isFilterClick: "filters/filters/isFilterClick",
      isOfferClick: "filters/filters/isOfferClick",
      sort: "filters/filters/sort",
      reload: "reload/reload/reload",
      modalComponent: "modal/modal-main/modalComponent",
      isNight: "settings/settings/isNight"
    }),
    hasRouteTransition() {
      return this.$route.name !== "category-mark-model-car";
    },
    urlValidate() {
      return this.$route.params.category === "cars";
    }
  },
  mounted() {
    const scrollTrumb = this.$refs.scrollTrumb;
    window.addEventListener("scroll", e => {
      // console.log(e.target.body.scrollHeight- window.innerHeight,window.scrollY )
      // console.log(window.scrollY * 100 / (e.target.body.scrollHeight- window.innerHeight))

      scrollTrumb.animate(
        {
          height: `${(window.scrollY * 100) /
            (e.target.body.offsetHeight - window.innerHeight)}%`
        },
        {
          duration: 10,
          fill: "forwards"
        }
      );
    });

    let dateObj = new Date();
    let timezoneOffset = -(dateObj.getTimezoneOffset() / 60);
    dateObj.setTime(dateObj.getTime() + ((timezoneOffsetCurrent - timezoneOffset) * 60 * 60 * 1000))

    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let hours = dateObj.getHours();
    this.setNight(hours >= 21 || hours < 9);
    if ((month === 12 && day === 31) || (month === 1 && day <= 2)) {
      if (
        //проверка на вермя с 18:00 31 ого
        (month === 12 && day === 31 && hours >= 18) ||
        //проверка на 1ое января
        (month === 1 && day === 1) ||
        //проверка на вермя с до 9:00 2ого
        (month === 1 && day === 2 && hours <= 9)
      ) {
        this.setNight("NY");
      }
    }

    // setTimeout(() => {
    // 	let payload = {
    // 		modal_data: {},
    // 		// modal_component: 'modal-credit',
    // 		modal_component: 'modal-timer',
    // 		modal_title: 'ЗАЯВКА НА АВТОКРЕДИТ',
    // 		modal_sub_title: {}
    // 	}
    // 	this.openModal(payload)
    // }, 60000 * 3)
  },
  methods: {
    ...mapMutations({
      setChosen: "filters/filters/SET_CHOSEN",
      resetChosen: "filters/filters/RESET_CHOSEN",
      setComponentCatalog: "filters/filters/SET_COMPONENT_CATALOG",
      setIsFilterClick: "filters/filters/SET_IS_FILTER_CLICK",
      setIsOfferClick: "filters/filters/SET_IS_OFFER_CLICK",
      setLoadingRange: "filters/filters/SET_LOADING_RANGE",
      setModalMenu: "modal/modal-menu/setModalMenu",
      setModalMarks: "modal/modal-marks/setModalMarks",
      setNight: "settings/settings/SET_NIGHT"
    }),
    ...mapActions({
      request: "filters/filters/request",
      // closeStories: 'stories/stories/closeStories',
      closeModal: "modal/modal-main/closeModal",
      openModal: "modal/modal-main/openModal",
      clearData: "modal/modal-choose/clearData"
    }),
    closeModals() {
      // this.closeStories()
      this.closeModal();
      this.setModalMenu(false);
      this.setModalMarks(false);
    },
    async onlyFilterRequest(assignVariables) {
      try {
        let response = await this.request({
          query: onlyFiltersRequest,
          variables: assignVariables
        });
        this.resetChosen();
        // this.setChosen({ key: 'priceFrom', value: this.inputFrom })
        // this.setChosen({ key: 'priceTo', value: this.inputTo })

        await this.changingFiltersIndexRequest(response.data?.offerFilters);
      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: "404" });
      }
    },
    async changingFiltersIndexRequest(payload) {
      await this.$store.commit("filters/filters/SET_FILTERS", payload);
      await this.$store.commit("filters/filters/SET_ALL_CHOSEN", {});
    },
    async filterRequest(assignVariables) {
      try {
        let response = await this.request({
          query: offerFilters,
          variables: assignVariables
        });
        await this.changingFilters(response.data.offerFilters);

        // console.log("SET_ALL_CHOSEN", response.data)
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'priceFrom', value: response.data.offerFilters.price[0] })
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'priceTo', value: response.data.offerFilters.price[1] })
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'yearFrom', value: response.data.offerFilters.year[0] })
        // await this.$store.commit('filters/filters/SET_CHOSEN', { key: 'yearTo', value: response.data.offerFilters.year[1] })
        await this.changingOffers(response.data.offers);
        this.setIsFilterClick(false);
      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: "404" });
      }
    },
    async changingFilters(payload) {
      // console.log('changingFilters',payload)

      await this.$store.commit("filters/filters/SET_FILTERS", payload);
      // await this.$store.commit('filters/filters/SET_ALL_CHOSEN', payload.chosen)
    },
    async changingOffers(payload) {
      await this.$store.commit("filters/filters/SET_OFFERS", payload);
      await this.$store.commit(
        "filters/filters/SET_FILTERED_TOTAL",
        payload.total
      );
      await this.$store.commit("filters/filters/SET_LOADING", false);
    },
    async checkUrl() {
      if (!this.isFilterClick) {
        await this.setLoadingRange(true);
      }
      // Если клик по объявлению, сразу показываем компонент объявления
      if (this.isOfferClick) {
        this.setComponentCatalog("car");
        this.setIsOfferClick(false);
        return;
      }
      await this.$store.commit("filters/filters/SET_LOADING", true);
      // всегда компонент каталога
      await this.setComponentCatalog("catalog-used");

      // Запрос на определение что это, когда 4 элемента в урл и нет клика по фильтру
      if (this.$route.params.car && !this.isFilterClick) {
        try {
          let response = await this.request({
            query: offerUrl,
            variables: { url: this.$route.path }
          });
          let typeName = response.data.offerUrl.__typename;
          let assignVariables = response.data.offerUrl;
          let queries = {
            engine_type_id_array: this.$numberToArray(
              this.$route.query.engine_type_id_array
            ),
            gearbox_id_array: this.$numberToArray(
              this.$route.query.gearbox_id_array
            ),
            drive_type_id_array: this.$numberToArray(
              this.$route.query.drive_type_id_array
            ),
            body_type_id_array: this.$numberToArray(
              this.$route.query.body_type_id_array
            ),
            price_from: Number(this.$route.query.price_from),
            price_to: Number(this.$route.query.price_to),
            year_from: Number(this.$route.query.year_from),
            year_to: Number(this.$route.query.year_to),
            sort: this.$route.query.sort || this.sort,
            page: Number(this.$route.query.page) || 1,
            limit: 16
          };

          delete assignVariables.__typename;
          if (typeName === "OfferUrlFilterPaginationType") {
            // Если это результат для фильтра, отправляем запрос
            await this.filterRequest(
              this._.pickBy({ ...assignVariables, ...queries })
            );
          } else if (typeName === "OfferUrlType") {
            // Если это авто, просто показываем компонент, запрос отправится в компоненте
            this.setComponentCatalog("car");
          }
        } catch (e) {
          this.$nuxt.error({ statusCode: 404 });
        }
      } else {
        await this.filterRequest(
          this._.pickBy({
            // TODO очищаю от пустых значений
            url: this.$route.path,
            page: Number(this.$route.query.page) || 1,
            dateFormat: "j F Y года.",
            mark_slug_array: this.$stringToArray(
              this.$route.query.mark_slug_array
            ),
            folder_slug_array: this.$stringToArray(
              this.$route.query.folder_slug_array
            ),
            generation_slug_array: this.$stringToArray(
              this.$route.query.generation_slug_array
            ),
            engine_type_id_array: this.$numberToArray(
              this.$route.query.engine_type_id_array
            ),
            gearbox_id_array: this.$numberToArray(
              this.$route.query.gearbox_id_array
            ),
            drive_type_id_array: this.$numberToArray(
              this.$route.query.drive_type_id_array
            ),
            body_type_id_array: this.$numberToArray(
              this.$route.query.body_type_id_array
            ),
            price_from: Number(this.$route.query.price_from),
            price_to: Number(this.$route.query.price_to),
            year_from: Number(this.$route.query.year_from),
            year_to: Number(this.$route.query.year_to),
            sort: this.$route.query.sort || this.sort,
            limit: 16
          })
        );
      }
      if (!this.isFilterClick) {
        await this.setLoadingRange(false);
      }
    }
  }
};
