
import { mapActions, mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			modalTitle: 'modal/modal-main/modalTitle',
			modalSubTitle: 'modal/modal-main/modalSubTitle',
			modalComponent: 'modal/modal-main/modalComponent'
		}),
	},
	methods: {
		...mapActions({
			closeModal: 'modal/modal-main/closeModal'
		})
	}
}
